import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ACTIONS, URLS } from '../_config';
import { useUserApi } from '../store/api/hooks/userApiHook';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { createURL } from '../_common/functions';

const ForgotPassword: React.FC = () => {
    const uaerAPI:any = useUserApi();
    const { register, formState: { errors }, handleSubmit, getValues } = useForm<any>();
    const navigate = useNavigate();

    const login = (data: any) => {
        uaerAPI.forgotPassword(data, (response: any) => {
            console.log('response =>', response);
            toast.success('Password reset OTP sent to your email');
            navigate(createURL(URLS.RESET_PASSWORD, { email: btoa(getValues('email')) }));
        }, (error: any) => {
            toast.error(error);
        });
    };

    return (
        <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img className="mx-auto h-10 w-auto" src="/logo.png" alt="Your Company"/>
                    <h2 className="mt-10 text-center text-2xl/9 font-bold tracking-tight text-gray-900">Forgot Password</h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form onSubmit={handleSubmit(login)}>
                    <div className='mb-5'>
                        <label htmlFor="email" className="block text-sm/6 font-medium text-gray-900">Email address</label>
                        <div className="mt-2">
                            <input type="email" {...register("email", { required: true })} autoComplete='username' className={`block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 ${errors.email?'outline-red-500 outline-2':'outline-gray-300'} placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6`}/>
                        </div>
                    </div>

                    <div className='mb-5'>
                        <button type="submit" className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Submit</button>
                    </div>
                </form>

                <p className="mt-10 text-center text-sm/6 text-gray-500">
                   
                    <Link to={URLS.LOGIN} className="font-semibold text-indigo-600 hover:text-indigo-500">Login</Link>
                </p>
            </div>
        </div>
    );
};

export default ForgotPassword;