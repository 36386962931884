import React, { useEffect, useRef, useState } from 'react';
import PanelHeading from './panelHeading';
import readXlsxFile from 'read-excel-file'
import Icon from '../../../components/icons';
import XLSX from 'sheetjs-style'
import * as FileSaver from 'file-saver'
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useEditorDesignSelector } from '../../../store/selectors/editorSelector';
import { useOrganization } from '../../../store/selectors/organizationSelector';
import { useUserApi } from '../../../store/api/hooks/userApiHook';
import { useUserSelector } from '../../../store/selectors/userSelector';
import { ACTIONS } from '../../../_config';
import { useDispatch } from 'react-redux';
import { convertSVGToPNG } from '../../../_common/functions';

interface RosterProps {
    svgRef: any;
}

const Roster: React.FC<RosterProps> = ({ svgRef }) => {
    const organization: any = useOrganization();
    const user: any = useUserSelector();
    const item: any = useEditorDesignSelector();
    const userAPI: any = useUserApi();
    const dispatch = useDispatch();

    const [correctRows, setCorrectRows] = useState<any>(null);
    const [selectedFiles, setSelectedFiles] = useState<any>(null);
    const [uploadRosterDisabled, setUploadRosterDisabled] = useState<boolean>(false);

    const tbodyRef: any = useRef();
    const tableHeadRowRef: any = useRef();
    const { formState: { errors }, handleSubmit, reset } = useForm();


    const dataTypes: any = [
        { title: 'Player Name', type: 'string', required: true, apiKey: 'playerName' },
        { title: 'Player Number', type: 'number', required: true, apiKey: 'playerNumber', min: 1, max: 2 },
    ]
    const AddErrorInfo = (msg: string) => {
        return `<div class="info flex gap-1">
                <svg onmouseenter="showInfo(this)" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path></svg>
                <div class="tip">
                    <p>${msg}</p>
                </div>
            </div>
        `
    }
    const validateCellData = (index: number, rowData: any, newRow: any) => {
        let data = rowData[index];
        if (data == null) { data = '' }
        data = data.toString();
        let field: any = dataTypes[index];
        let cellStatus = true;
        if (field) {
            let newCell = newRow.insertCell(); // add td
            if (data.trim() == "" && field.required) {
                newCell.classList.add('cell-error');
                newCell.innerHTML = data + AddErrorInfo('Data Required');
                cellStatus = false;
            } else {
                switch (field.type) {
                    case 'number':
                        let nStatus = false;
                        data = data.trim();
                        if (data == '' && !field.required) {
                            newCell.innerHTML = data;
                            nStatus = true;
                        } else {
                            if (!isNaN(data)) {
                                if (field.min && field.max) {
                                    if (data.length < field.min || data.length > field.max) {
                                        if (field.required) {
                                            newCell.classList.add('cell-error');
                                        } else {
                                            newCell.classList.add('cell-warn');
                                        }
                                        newCell.innerHTML = data + AddErrorInfo(`Value should be ${field.min} to ${field.max} characters.`);
                                    } else {
                                        newCell.innerHTML = data;
                                        nStatus = true;
                                    }
                                } else {
                                    newCell.innerHTML = data;
                                    nStatus = true;
                                }
                            } else {
                                newCell.innerHTML = data + AddErrorInfo(`Value should be a Number.`);
                                if (field.required) {
                                    newCell.classList.add('cell-error');
                                } else {
                                    newCell.classList.add('cell-warn');
                                }
                            }

                        }

                        if (field.required) {
                            if (!nStatus) { cellStatus = false; }
                        } else {
                            cellStatus = true;
                        }


                        break;
                    default:
                        newCell.innerHTML = data;
                        break;
                }
            }
        }
        return cellStatus;
    }


    const onSelectFile = (e: any) => {
        if (e.target.files.length > 0) {
            const selectedFile = e.target.files[0];
            readXlsxFile(selectedFile).then((rows: any) => {
                setSelectedFiles(selectedFile);


                tableHeadRowRef.current.innerHTML = "";
                tbodyRef.current.innerHTML = "";
                let checkedRow: any = [];
                for (let row = 0; row < rows.length; row++) {
                    let rowColData = rows[row]
                    if (row == 0) {
                        for (let col = 0; col < dataTypes.length; col++) {
                            let th = document.createElement('th');
                            tableHeadRowRef.current.appendChild(th);
                            th.innerHTML = dataTypes[col].title;
                        }
                    } else {
                        let rowStatus = true;
                        let newRow = tbodyRef.current.insertRow();
                        for (let col = 0; col < dataTypes.length; col++) {
                            let cellStatus = validateCellData(col, rowColData, newRow);
                            if (!cellStatus) { rowStatus = false }
                        }
                        if (rowStatus) {
                            checkedRow.push(rowColData)
                        }
                    }
                }
                setCorrectRows(checkedRow);


                if (checkedRow.length > 0 && checkedRow.length == rows.length - 1) {
                    setUploadRosterDisabled(false);
                } else {
                    let errorMsg = (checkedRow.length == 0) ? 'No valid data found.' : 'Some data are invalid.';

                    toast.error(errorMsg);
                    setUploadRosterDisabled(true);
                }
            })
        }
    }

    useEffect(() => {
        (window as any).showInfo = (svg: any) => {
            let tip = svg.nextElementSibling;
            if (svg.parentElement.parentElement.offsetLeft > 300) {
                tip.style.marginLeft = '-300px'
            }
        }
        return () => {
            delete (window as any).showInfo
        }
    }, [])

    const downloadSampleExcel = async () => {
        let fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let fileExt = '.xlsx';
        let data: any = {};
        dataTypes.map((field: any) => {
            data[field.title] = '';
        })
        let ws: any = XLSX.utils.json_to_sheet([data])
        let wb: any = { Sheets: { 'data': ws }, SheetNames: ['data'] }
        let excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        let _data: any = new Blob([excelBuffer], { type: fileType })
        FileSaver.saveAs(_data, 'Rosters_sample' + fileExt)
    }


    const updateDesign = (actionKey: string, payload: any) => {
        dispatch({ type: actionKey, payload: payload });
    };

    const trClickHandler = (e: any) => {
        let tr = e.target.parentElement;
        if (tr.tagName == 'TR') {
            let trs = tbodyRef.current.getElementsByTagName('tr');
            for (let i = 0; i < trs.length; i++) {
                trs[i].classList.remove('selected');
            }
            tr.classList.add('selected');
            let firstTdValue = tr.getElementsByTagName('td')[0].innerText;
            let secondTdValue = tr.getElementsByTagName('td')[1].innerText;


            console.log('firstTdValue:', firstTdValue);
            console.log('secondTdValue:', secondTdValue);
            if (typeof secondTdValue === 'string' && secondTdValue.trim() != '' && secondTdValue.length <= 2) {
                let n = secondTdValue.replace(/[^\d.]/g, '')
                n = n.toString().substring(0, 2);
                dispatch({ type: ACTIONS.EDITOR.UPDATE_DESIGN, payload: { design: { ...item, ...{ data: { ...item.data, number: { ...item.data.number, number: n } } } } } });

                if (typeof firstTdValue === 'string' && firstTdValue.trim() != '' && firstTdValue != 'Data Required') {
                    //updateDesign(ACTIONS.EDITOR.TEXT.FRONT.TEXT, { text: firstTdValue });
                    updateDesign(ACTIONS.EDITOR.TEXT.BACK.TEXT, { text: firstTdValue });

                } else {
                    toast.error('Please select a valid row.');
                }
            } else {
                toast.error('Please select a valid row.');
            }
        }
    }

    const trHoverHandler = (e: any) => {
        let tr = e.target.parentElement;
        if (tr.tagName == 'TR') {
            let trs = tbodyRef.current.getElementsByTagName('tr');
            for (let i = 0; i < trs.length; i++) {
                trs[i].classList.remove('bg-green-100');
            }
            tr.classList.add('bg-green-100');
        }
    }

    const convertSVGToPNGHandler = (downloadOrNot = false) => {
        return new Promise((resolve, reject) => {
            let frontPNG = '';
            let backPNG = '';
            let front: any = svgRef?.current?.querySelector('[name="front"]')
            let box: any = front?.getBBox();

            convertSVGToPNG(svgRef, box?.x, box?.y, box?.width, box?.height).then((frontPNG_url: any) => {
                frontPNG = frontPNG_url;
                let back: any = svgRef?.current?.querySelector('[name="back"]')
                let box2: any = back?.getBBox();
                convertSVGToPNG(svgRef, box2?.x, box2?.y, box2?.width, box2?.height).then((backPNG_url: any) => {
                    backPNG = backPNG_url;
                    resolve({ frontPNG, backPNG });
                });
            });
        });
    }

    const uploadRosterAndSaveDesign = (params: any) => {
        return new Promise((resolve, reject) => {
            if (item.design_id) {
                params.append('design_id', item.design_id);
                userAPI.updateDesign(params, (data: any) => {
                    resolve('Update successfully.');
                }, (error: any) => {
                    reject('Something was wrong. Please try again.');
                });
            } else {
                userAPI.saveDesign(params, (data: any) => {
                    resolve('Saved successfully.');
                }, (error: any) => {
                    reject('Something was wrong. Please try again.');
                });
            }

        })
    }

    const saveFormHandler = async (data: any) => {

        const formData = new FormData();
        formData.append('app_id', organization.app_id);
        formData.append('user_id', user.userID);
        formData.append('item_id', item.id);
        formData.append('user_name', user.first_name + ' ' + user.last_name);
        formData.append('user_email', user.email);
        formData.append('user_profile_image', user.profilePicture);
        formData.append('design_data', JSON.stringify(item));
        formData.append('roster', selectedFiles);

        convertSVGToPNGHandler().then((images: any) => {
            formData.append('front_thumbnail', images.frontPNG);
            formData.append('back_thumbnail', images.backPNG);
            toast.promise(
                uploadRosterAndSaveDesign(formData), {
                loading: 'Uploading...',
                success: (msg: any) => {
                    return <b>{msg}</b>
                },
                error: (msg: any) => { return <b>{msg}</b> },
            });
        });
    }

    return (
        <div>
            <PanelHeading title='Upload Team Roster' />

            <form className='py-3' onSubmit={handleSubmit(saveFormHandler)} encType="multipart/form-data">
                <input type="file" name='roster' onChange={onSelectFile} accept=".xlsx" className="block w-full text-xs text-slate-900 border border-slate-900 rounded-md cursor-pointer bg-gray-50  focus:outline-none placeholder-gray-400" />

                <div className='flex gap-3 mt-2 float-end' onClick={downloadSampleExcel} ><u>Download Sample File</u>  <Icon name='download-file' size={20} /> </div>

                <div>
                    <div className={`w-full table-responsive list-table pb-2 ${correctRows && 'h-[50vh]'} overflow-auto`} >
                        <table className="table table-striped csv-table">
                            <thead>
                                <tr ref={tableHeadRowRef} className='bg-blue-100 text-slate-800'></tr>
                            </thead>
                            <tbody ref={tbodyRef} onClick={trClickHandler} onMouseOver={trHoverHandler}> </tbody>
                        </table>
                    </div>
                    {(correctRows != null) && <h6 className='pt-2'>Got {correctRows?.length} row{correctRows?.length ? 's' : ''} can be imported</h6>}
                    <div className='flex justify-end gap-2'>
                        {(correctRows != null) &&
                            <>
                                <button className='flex gap-2 items-center bg-primary px-3 py-2 rounded-md text-gray-50'
                                    onClick={() => {
                                        setCorrectRows(null);
                                        tableHeadRowRef.current.innerHTML = "";
                                        tbodyRef.current.innerHTML = "";
                                        reset({ roster: null });
                                    }}>
                                    <Icon name='clear' size={18} />
                                    <span className='text-md font-medium' >Cancel</span>
                                </button>

                                <button className={`flex gap-2 items-center px-3 py-2 rounded-md text-gray-50 ${uploadRosterDisabled ? 'bg-primary cursor-not-allowed' : 'bg-green-500'}`}
                                    disabled={uploadRosterDisabled}>
                                    <Icon name='save' size={18} />
                                    <span className='text-md font-medium' >Upload</span>
                                </button>
                            </>}
                    </div>

                    <div>
                        <div>

                            <div className="steps flex flex-col gap-4 mt-4">
                                <div className="flex relative">
                                    <h4 className="mr-4 mt-1 bg-blue-500 text-white rounded-full w-6 h-6 flex items-center justify-center">1</h4>
                                    <div className="absolute left-3 top-6 h-full border-l-2 border-blue-500"></div>
                                    <div>
                                        <h4>Download Sample File</h4>
                                        <p className='text-xs'>Click on the "Download Sample File" link to download a sample Excel file.</p>
                                    </div>
                                </div>
                                <div className="flex relative">
                                    <h4 className="mr-4 mt-1 bg-blue-500 text-white rounded-full w-6 h-6 flex items-center justify-center">2</h4>
                                    <div className="absolute left-3 top-6 h-full border-l-2 border-blue-500"></div>
                                    <div>
                                        <h4>Fill in the Sample File</h4>
                                        <p className='text-xs'>Open the downloaded file and fill in the required details for each player.</p>
                                    </div>
                                </div>
                                <div className="flex relative">
                                    <h4 className="mr-4 mt-1 bg-blue-500 text-white rounded-full w-6 h-6 flex items-center justify-center">3</h4>
                                    <div className="absolute left-3 top-6 h-full border-l-2 border-blue-500"></div>
                                    <div>
                                        <h4>Upload the Filled File</h4>
                                        <p className='text-xs'>Click on the "Choose File" button to select and upload the filled Excel file.</p>
                                    </div>
                                </div>
                                <div className="flex relative">
                                    <h4 className="mr-4 mt-1 bg-blue-500 text-white rounded-full w-6 h-6 flex items-center justify-center">4</h4>
                                    <div>
                                        <h4>Save the Roster</h4>
                                        <p className='text-xs'>After uploading, click on the "Upload" button to save the roster.</p>
                                    </div>
                                </div>
                            </div>


                            {/* <div className="steps flex flex-col gap-4 mt-4"> 
                                <div className="step flex">
                                    <h4 className="mr-4">Step 1:</h4>
                                    <div>
                                        <h4>Download Sample File</h4>
                                        <p>Click on the "Download Sample File" link to download a sample Excel file.</p>
                                    </div>
                                </div>
                                <div className="step flex">
                                    <h4 className="mr-4">Step 2:</h4>
                                    <div>
                                        <h4>Fill in the Sample File</h4>
                                        <p>Open the downloaded file and fill in the required details for each player.</p>
                                    </div>
                                </div>
                                <div className="step flex">
                                    <h4 className="mr-4">Step 3:</h4>
                                    <div>
                                        <h4>Upload the Filled File</h4>
                                        <p>Click on the "Choose File" button to select and upload the filled Excel file.</p>
                                    </div>
                                </div>
                                <div className="step flex">
                                    <h4 className="mr-4">Step 4:</h4>
                                    <div>
                                        <h4>Save the Roster</h4>
                                        <p>After uploading, click on the "Upload" button to save the roster.</p>
                                    </div>
                                </div>
                            </div> */}

                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Roster;