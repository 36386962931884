import React, { useEffect } from 'react';
import Select from 'react-select';
import Icon from '../../../components/icons';
import PanelHeading from './panelHeading';
import { useDispatch } from 'react-redux';
import { ACTIONS } from '../../../_config';
import { useEditorDesignSelector } from '../../../store/selectors/editorSelector';
import { useEditorApi } from '../../../store/api/hooks/editorApiHook';
import { useOrganization } from '../../../store/selectors/organizationSelector';

const PickDesign: React.FC = () => {

    const editorAPI:any = useEditorApi(); 
    const organizationData: any = useOrganization();
    const designTypes = [
        { value: 'jersey', label: 'Custom Football Jerseys' },
        { value: 'pants', label: 'Custom Football Pants' }
    ];

    const [selectedDesign, setSelectedDesign] = React.useState<any>(designTypes[0]);
    const item: any = useEditorDesignSelector();
    const [jerseys, setJerseys] = React.useState<any[] | null>(null);
    const [pants, setPants] = React.useState<any[] | null>(null);

    const dispatch = useDispatch();

    const selectItemForDesign = (item: any) => {
        dispatch({ type: ACTIONS.EDITOR.UPDATE_DESIGN, payload: { design: item } });
    }

    const getItems = () => {
        editorAPI.getItems({ app_id: organizationData?.app_id }, (message:any,response: any) => {
           
            let items:any = response.list;
            items = items.map((item:any) => {
                item.data = JSON.parse(item.data);
                return item;
            });
            setJerseys(items);
            /* let jerseys:any = items.filter((item:any) => item.category == 'Tshirt');
            let pants:any = items.filter((item:any) => item.category == 'Pant');
            setJerseys(jerseys);
            setPants(pants); */
        }, (error: any) => {
            console.error('error =>', error);
        });
    }

    useEffect(() => {
        getItems();
    }, []);

    useEffect(() => {
        console.log('jerseys =>', jerseys);
    }, [jerseys]);

    return (
        <div>
            <PanelHeading title='Pick Design' />

            <Select
                className='mb-2 max-w-[320px]'
                value={selectedDesign}
                onChange={setSelectedDesign}
                options={designTypes}
            />

            {selectedDesign.value === 'jersey' && (
                <div>
                    {jerseys ? (
                        <div className='flex gap-3 flex-wrap'>
                            {jerseys.map(jersey => (
                                <div key={jersey.id} onClick={() => { selectItemForDesign(jersey) }} className={`cursor-pointer border-2 p-2 shadow-md flex-shrink-0 min-w-[100px] rounded-md ${item?.id == jersey.id ? 'border-green-700 shadow-slate-500' : 'border-slate-300'}`}>
                                    <img src={jersey.thumbnail} alt={jersey.item_name} className='w-[100px] h-[140px] object-contain' />
                                    <p className='text-center text-sm font-medium'>{jersey.item_name}</p>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <Icon name='loader' size={14} className='text-slate-900' />
                    )}
                </div>
            )}

            {selectedDesign.value === 'pants' && (
                <div>
                    {pants ? (
                        <ul>
                            {pants.map(pant => (
                                <li key={pant.id}>{pant.name}</li>
                            ))}
                        </ul>
                    ) : (
                        <Icon name='loader' size={14} className='text-slate-700' />
                    )}
                </div>
            )}
            
        </div>
    );
};

export default PickDesign;