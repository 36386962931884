import moment from "moment"

export const createURL = (url: string, params: any) => {
    Object.keys(params).map((param) => {
        url = url.replace(`:${param}`, params[param])
    })
    return url
}


export const UpdateWithVariables = (url: string, data: any) => {
    if (data) {
        let dynamicParams = url.match(/:[A-Z_]*/gi)
        dynamicParams?.map(key => {
            if (key.length > 1) url = url.replace(key, data[key.replace(':', '')])
        })
    }
    return url
}

export function CheckNumber(e: { target: { value: string; getAttribute: (arg0: string) => string } }) {
    let n = e.target.value.replace(/^0+/, '').replace(/[^\d.]/g, '')
    const min: number = parseFloat(e.target.getAttribute('min'))
    const max: number = parseFloat(e.target.getAttribute('max'))
    if (n == '') {
        n = '0'
    }
    if (min && parseFloat(n) < min) {
        n = String(min)
    }

    if (max && parseFloat(n) > max) {
        n = String(max)
    }
    e.target.value = n
}
export const showDate = (dbDate: any, dateFormat: any = 'MM-DD-YYYY') => {
    return (dbDate !== '') ? moment(dbDate).format(dateFormat) : "";
}

export const convertSVGToPNG = (svgRef: any, x: any, y: any, w: any, h: any, fileName = 'Converted-image', download: boolean = false) => {
    return new Promise((resolve, reject) => {
        const svgElement: any = svgRef.current;
        if (!svgElement) {
            console.error("No SVG element found.");
            return;
        }


        let originalViewBox = svgElement.getAttribute("viewBox");
        let originalWidth = svgElement.getAttribute("width");
        let originalHeight = svgElement.getAttribute("height");

        svgElement.setAttribute("width", w);
        svgElement.setAttribute("height", h);
        svgElement.setAttribute("viewBox", [x, y, w, h].join(' '));
        // Serialize SVG to a string
        const svgData = new XMLSerializer().serializeToString(svgElement);

        // Create a Blob URL for the SVG string
        const svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
        const url = URL.createObjectURL(svgBlob);

        // Create an image to render the SVG onto a canvas
        const img = new Image();
        img.onload = () => {
            // Create a canvas and set its dimensions to match the SVG
            const canvas = document.createElement("canvas");
            canvas.width = svgElement.viewBox.baseVal.width || svgElement.clientWidth;
            canvas.height = svgElement.viewBox.baseVal.height || svgElement.clientHeight;

            const ctx: any = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);

            // Convert the canvas to a PNG data URL
            const pngDataUrl = canvas.toDataURL("image/png");

            // Trigger download
            if (download) {
                const link = document.createElement("a");
                link.href = pngDataUrl;
                const time = moment().format("YYMMDD-HHmmss");
                link.download = fileName + "-" + time + ".png";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }


            // Clean up
            URL.revokeObjectURL(url);
            svgElement.setAttribute("viewBox", originalViewBox);
            svgElement.setAttribute("width", originalWidth);
            svgElement.setAttribute("height", originalHeight);
            resolve(pngDataUrl);
        };
        return img.src = url;
    });



}


export const calculatePdfTextY = (str: string) => {
    const result = str.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
}

export const camelCaseToTitleCase = (str: string) => {
    const result = str.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
}
