import React, { useEffect, useRef } from 'react';
import { useEditorDesignSelector } from '../../store/selectors/editorSelector';
import Jersey1 from '../../products/jerseys/jersey1';
import Jersey2 from '../../products/jerseys/jersey2';
import Jersey3 from '../../products/jerseys/jersey3';
import Jersey5 from '../../products/jerseys/jersey5';
import Jersey6 from '../../products/jerseys/jersey6';
import Jersey7 from '../../products/jerseys/jersey7';

interface DesignViewProps {
    svgRef: any;
}

const DesignView: React.FC<DesignViewProps> = ({ svgRef }) => {
    const item: any = useEditorDesignSelector();

    return (
        <div className='p-2 flex-grow bg-gray-100 flex items-center justify-center relative'>
            {item == null && <p className='text-center text-gray-500'>No Design Selected</p>}
            {item?.item_key == 'JERSEY002' && <Jersey1 data={item.data} svgRef={svgRef} />}
            {item?.item_key == 'JERSEY003' && <Jersey2 data={item.data} svgRef={svgRef} />}
            {item?.item_key == 'JERSEY004' && <Jersey3 data={item.data} svgRef={svgRef} />}
            {item?.item_key == 'JERSEY005' && <Jersey5 data={item.data} svgRef={svgRef} />}
            {item?.item_key == 'JERSEY006' && <Jersey6 data={item.data} svgRef={svgRef} />}
            {item?.item_key == 'JERSEY007' && <Jersey7 data={item.data} svgRef={svgRef} />}

        </div>
    );
};

export default DesignView;