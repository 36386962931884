import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { URLS } from '../_config';
import Header from '../components/header';
import DesignList from './gallery/DesignList';
import { useUserSelector } from '../store/selectors/userSelector';

const RiddellHome: React.FC = () => {
    const navigate  = useNavigate();
    const user:any = useUserSelector();

    const url: string = '/auth/1741102a-23bb-485a-8b2d-1b40253a6794/' + btoa(JSON.stringify({
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        userID: user.id,
        profilePicture: user.avatar.original
    }));

    useEffect(() => {
        navigate(url)
    }, []);
    return (
        <div className='h-screen flex flex-col'>
            <Header />
            <div className='  text-center py-2'>
                <Link to={url} className='btn bg-green-700 text-white'>Go to Builder</Link>
            </div>
        </div>
    );
};

export default RiddellHome;