import React, { HtmlHTMLAttributes } from 'react';

interface SvgJerseyTextureProps extends HtmlHTMLAttributes<SVGAElement> {}

const SvgJerseyTexture: React.FC<SvgJerseyTextureProps> = ({children}) => {
    return (
        <g name='texture-map'>
            <defs>
                <pattern id="bgimg" x="0" y="0" width="17.75" height="18" patternUnits="userSpaceOnUse">
                    <image x="0" y="0" width="17.75" height="18" href="/assets/images/pattern-cell.png" opacity={0.3} />
                </pattern>
            </defs>
            <mask id="mask">
                {children}
            </mask>
            {/* <rect x="0" y="0" width="100%" height="100%" fill="url(#bgimg)" mask="url(#mask)" /> */}
        </g>
    );
};

export default SvgJerseyTexture;