import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEditorApi } from '../store/api/hooks/editorApiHook';
import { useDispatch } from 'react-redux';
import { ACTIONS, URLS } from '../_config';
import toast from 'react-hot-toast';

const GearBuilderAuth: React.FC = () => {
    const editorAPI: any = useEditorApi();
    const params: any = useParams();

    const [key, setKey] = React.useState<string | null>(null);

    const dispach: any = useDispatch();

    const navigate: any = useNavigate();


    useEffect(() => {
        let appID = params.appID;
        let userBase64 = params.userBase64;
        let user = JSON.parse(atob(userBase64));

        //validate user object as {first_name:'',last_name:'',email:'', userID:1230, profilePicture:''}
        // var userTest = {first_name:'Dreamz',last_name:'Tech',email:'ayon.d@yopmail.com', userID:1230, profilePicture:''}
        // console.log('userTest', btoa(JSON.stringify(userTest)));

        if (
            typeof user.first_name === 'string' && user.first_name !== '' &&
            typeof user.last_name === 'string' && user.last_name !== '' &&
            typeof user.email === 'string' && user.email !== '' && user?.userID !== '' &&
            typeof user.profilePicture === 'string'
        ) {
            dispach({ type: ACTIONS.USER.LOGIN, payload: {
                user: user,
                accessToken: '',
                refreshToken: '',
                isAuthenticated: true,
                permissions: null
            } }); // save user object in redux store

            // if valid user then call get Organization...
            editorAPI.getOrganization({ urlParams: { appID: appID } }, (message: any, data: any) => {
                if (data && data?.details?.[0]?.app_id) {
                    setKey(data?.details?.[0]?.app_id);
                    let organization = data?.details?.[0];
                    // console.log('data =>', organization);

                    dispach({ type: ACTIONS.ORGANIZATION.ORGANIZATION, payload: organization }); // save user object in redux store
                    toast.success('Collecting organization data successfull');

                    // after all success -----
                    navigate(URLS.HOME);
                } else {
                    toast.error('Invalid organization key');
                }
            }, (error: any) => {
                console.log('error =>', error);
            });

            // get items of the organization..

        } else {
            console.error('Invalid user object', user);

            toast.error('Invalid user ID');
        }

    }, []);

    return (
        <div>
            <h1>Gear Builder Authentication</h1>
            <p>Please authenticate to access the Gear Builder.</p>
        </div>
    );
};

export default GearBuilderAuth;