import React, { useEffect, useState } from 'react';
import PanelHeading from './panelHeading';
import { useUserApi } from '../../../store/api/hooks/userApiHook';
import { useOrganization } from '../../../store/selectors/organizationSelector';
import { useUserSelector } from '../../../store/selectors/userSelector';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useEditorDesignSelector } from '../../../store/selectors/editorSelector';

const AddImage: React.FC = () => {
    const dispatch = useDispatch();
    const userAPI: any = useUserApi();
    const organizationData: any = useOrganization();
    const design:any = useEditorDesignSelector();
    const user: any = useUserSelector();
    const [tab, setTab] = useState('front');
    const [imageList, setImageList] = useState<any>(null);
    const [selectedFiles, setSelectedFiles] = useState<any>(null);

    const getMyImageList = () => {
        userAPI.getImages({ app_id: organizationData?.app_id, user_id: user.userID }, (message: any, response: any) => {
            setImageList(response?.list);
            console.log('imageList =>', response?.list);

        }, (error: any) => {
            console.error('error =>', error);
        });
    }


    const imageUploadAndSave = (params: any) => {
        return new Promise((resolve, reject) => {
            userAPI.uploadImage(params, (data: any) => {
                resolve('Upload successfully.');
                getMyImageList();
            }, (error: any) => {
                reject('Something was wrong. Please try again.');
            });
        });
    }
    const imageUploadHandler = async (selectFile: any) => {
        const formData = new FormData();
        formData.append('app_id', organizationData?.app_id);
        formData.append('user_id', user.userID);
        formData.append('image', selectFile);
        toast.promise(
            imageUploadAndSave(formData), {
            loading: 'Uploading...',
            success: (msg: any) => {
                return <b>{msg}</b>
            },
            error: (msg: any) => { return <b>{msg}</b> },
        });
    }

    const imgChangeHandler = (e: any) => {
        console.log('e =>', e.target.files[0]);
        setSelectedFiles(e.target.files[0]);
        imageUploadHandler(e.target.files[0]);
    }

    const addImage = (image: any) => {
        console.log('image =>', image);
        return;
        if (tab == 'front') {
            
            dispatch({ type: 'ACTIONS/EDITOR/UPDATE_DESIGN_DATA', payload: { front_logo: image.image } });
        } else {
            
        }
    }


    useEffect(() => {
        getMyImageList();
    }, []);

    return (
        <div>
            <PanelHeading title='Add Team Logo' />
            <div className='flex gap-1 border-b border-slate-900'>
                <button onClick={() => { setTab('front') }} className={`px-3 py-1 rounded-lg rounded-b-none text-sm font-semibold ${tab == 'front' ? 'bg-slate-900 text-slate-50' : 'bg-slate-100 text-slate-500'}`}>Front</button>
                <button onClick={() => { setTab('back') }} className={`px-3 py-1 rounded-lg rounded-b-none text-sm font-semibold ${tab == 'back' ? 'bg-slate-900 text-slate-50' : 'bg-slate-100 text-slate-500'}`}>Back</button>
            </div>

            {tab == 'front' && (
                <form className='py-3'>
                    <input type="file" accept='.jpg,.png' onChange={imgChangeHandler}
                        className="block w-full text-xs text-slate-900 border border-slate-900 rounded-md cursor-pointer bg-gray-50  focus:outline-none placeholder-gray-400" />
                </form>
            )}
            {tab == 'back' && (
                <form className='py-3'>
                    <input type="file" accept='.jpg,.png' onChange={imgChangeHandler}
                        className="block w-full text-xs text-slate-900 border border-slate-900 rounded-md cursor-pointer bg-gray-50  focus:outline-none placeholder-gray-400" />
                </form>
            )}

            <div className='flex flex-wrap gap-3 mt-3'>
                {
                    imageList?.length > 0 && imageList?.map((image: any) => (
                        <div key={image.id} className='border border-slate-300 rounded-md p-2 hover:border-slate-400 hover:cursor-pointer' >
                            <img src={image.image} alt={image.image} className='w-20 h-20 object-cover' onClick={()=>{addImage(image)}} />
                        </div>
                    ))
                }
            </div>

        </div>
    );
};

export default AddImage;