import React from 'react';
import Icon from '../../components/icons';
import SidebarMenuButton from './components/sidebarMenuButton';
import { ACTIONS, KEYS } from '../../_config';
import { useEditorDesignSelector, useEditorPanelSelector } from '../../store/selectors/editorSelector';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';

interface SidebarProps {

}

const Sidebar: React.FC<SidebarProps> = ({ }) => {

    const dispach = useDispatch();
    const panel = useEditorPanelSelector();
    const item: any = useEditorDesignSelector();

    const checkProductSelected = (TAB_NAME: string) => {
        console.log('item', item);
        if (item == null) {
            toast.error('Please select a product first.');
            return false;
        } else {
            if (TAB_NAME === 'CHANGE_COLOR') {
                dispach({ type: ACTIONS.EDITOR.CHANGE_EDIT_PANEL, payload: { editorPanel: KEYS.EDIT_PANELS.CHANGE_COLOR } })
            } else if (TAB_NAME === 'ADD_TEXT') {
                dispach({ type: ACTIONS.EDITOR.CHANGE_EDIT_PANEL, payload: { editorPanel: KEYS.EDIT_PANELS.ADD_TEXT } })
            } else if (TAB_NAME === 'JERSEY_NUMBER') {
                dispach({ type: ACTIONS.EDITOR.CHANGE_EDIT_PANEL, payload: { editorPanel: KEYS.EDIT_PANELS.JERSEY_NUMBER } })
            } else if (TAB_NAME === 'ADD_IMAGE') {
                dispach({ type: ACTIONS.EDITOR.CHANGE_EDIT_PANEL, payload: { editorPanel: KEYS.EDIT_PANELS.ADD_IMAGE } })
            } else if (TAB_NAME === 'ROSTER') {
                dispach({ type: ACTIONS.EDITOR.CHANGE_EDIT_PANEL, payload: { editorPanel: KEYS.EDIT_PANELS.ROSTER } })
            } else if (TAB_NAME === 'READY_TO_GO') {
                dispach({ type: ACTIONS.EDITOR.CHANGE_EDIT_PANEL, payload: { editorPanel: KEYS.EDIT_PANELS.READY_TO_GO } })
            }
        }
    }
    return (
        <div className='w-[48px] flex-grow-0 flex-shrink-0 bg-gray-200'>
            <div className='w-full'>
                <SidebarMenuButton icon='jersey' onClick={() => dispach({ type: ACTIONS.EDITOR.CHANGE_EDIT_PANEL, payload: { editorPanel: KEYS.EDIT_PANELS.PICK_DESIGN } })} active={panel == KEYS.EDIT_PANELS.PICK_DESIGN} />
                <SidebarMenuButton icon='brush' onClick={() => checkProductSelected('CHANGE_COLOR')} active={item?.id > 0 && panel == KEYS.EDIT_PANELS.CHANGE_COLOR} />
                <SidebarMenuButton icon='text' onClick={() => checkProductSelected('ADD_TEXT')} active={item?.id > 0 && panel == KEYS.EDIT_PANELS.ADD_TEXT} />
                <SidebarMenuButton icon='number' onClick={() => checkProductSelected('JERSEY_NUMBER')} active={item?.id > 0 && panel == KEYS.EDIT_PANELS.JERSEY_NUMBER} />
                <SidebarMenuButton icon='image' onClick={() => checkProductSelected('ADD_IMAGE')} active={item?.id > 0 && panel == KEYS.EDIT_PANELS.ADD_IMAGE} />
                <SidebarMenuButton icon='users' onClick={() => checkProductSelected('ROSTER')} active={item?.id > 0 && panel == KEYS.EDIT_PANELS.ROSTER} />
                <SidebarMenuButton icon='rocket' onClick={() => checkProductSelected('READY_TO_GO')} active={item?.id > 0 && panel == KEYS.EDIT_PANELS.READY_TO_GO} />
            </div>
        </div>
    );
};

export default Sidebar;