import React, { HtmlHTMLAttributes } from 'react'
import { BsImage } from 'react-icons/bs';
import { SiRocket } from "react-icons/si";
import { FaBrush, FaDownload, FaFilePdf } from 'react-icons/fa6';
import { TbCircleDashedNumber7, TbFileTypeXls } from "react-icons/tb";
import { IoIosSave } from "react-icons/io";
import { PiTextAaBold, PiTShirt } from "react-icons/pi";
import { BiLogOut, BiSolidUserCircle } from "react-icons/bi";
import LoaderSVG from './iconSvgs/loader';
import { FaUsers } from "react-icons/fa";
import { GrDocumentDownload, GrClear } from "react-icons/gr";

export type iconNames = 'jersey' | 'brush' | 'text' | 'number' | 'image' | 'rocket' | 'loader'
    | 'download' | 'download-file' | 'save' | 'logout'
    | 'user' | 'users' | 'clear' | 'pdf' | 'xls';

interface props extends HtmlHTMLAttributes<HTMLDivElement> {
    name: iconNames;
    size?: any;
    className?: string;
    onClick?: (e: any) => void
}

const Icon: React.FC<props> = ({
    children,
    name,
    size = '24',
    className = '',
    onClick,
    ...args
}) => {


    const getIconByName = (name: iconNames) => {
        switch (name) {
            case 'jersey': return <PiTShirt style={{ fontSize: `${size}px` }} />;
            case 'brush': return <FaBrush style={{ fontSize: `${size}px` }} />;
            case 'text': return <PiTextAaBold style={{ fontSize: `${size}px` }} />;
            case 'number': return <TbCircleDashedNumber7 style={{ fontSize: `${size}px` }} />;
            case 'image': return <BsImage style={{ fontSize: `${size}px` }} />;
            case 'rocket': return <SiRocket style={{ fontSize: `${size}px` }} />;
            case 'download': return <FaDownload style={{ fontSize: `${size}px` }} />;
            case 'save': return <IoIosSave style={{ fontSize: `${size}px` }} />;
            case 'logout': return <BiLogOut style={{ fontSize: `${size}px` }} />;
            case 'user': return <BiSolidUserCircle style={{ fontSize: `${size}px` }} />;
            case 'users': return <FaUsers style={{ fontSize: `${size}px` }} />;
            case 'download-file': return <GrDocumentDownload style={{ fontSize: `${size}px` }} />;
            case 'clear': return <GrClear style={{ fontSize: `${size}px` }} />;
            case 'pdf': return <FaFilePdf style={{ fontSize: `${size}px` }} />;
            case 'xls': return <TbFileTypeXls style={{ fontSize: `${size}px` }} />;

            case 'loader': return <LoaderSVG size={size} />;

            default: return null;
        }
    }

    const getStyle = () => {
        let style: any = {}
        if (onClick) {
            style.cursor = 'pointer';
        }
        return style
    }

    return (
        <span onClick={onClick ? onClick : (e: any) => { }} className={`${className} ${(onClick) ? 'cursor-pointer' : ''}`}
            style={getStyle()}>
            {getIconByName(name)}
        </span>
    )
}

export default Icon;
