import React from 'react';
import { Link } from 'react-router-dom';
import { URLS } from '../_config';
import Header from '../components/header';
import DesignList from './gallery/DesignList';

const Home: React.FC = () => {
    return (
        <div className='h-screen flex flex-col'>
            <Header />
            <div className=' '>
                <Link to={URLS.DESIGN} className='btn btn-primary'>Go to the Design Page</Link>


                <DesignList />

            </div>



        </div>
    );
};

export default Home;