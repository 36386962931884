import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ACTIONS, URLS } from '../_config';
import { useUserApi } from '../store/api/hooks/userApiHook';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

const ResetPassword: React.FC = () => {
    const uaerAPI:any = useUserApi();
    const { register, formState: { errors }, handleSubmit, } = useForm<any>();
    const navigate = useNavigate();

    const params:any = useParams();

    const resetPassword = (data: any) => {
        uaerAPI.resetPassword({...data,email:atob(params.email)}, (response: any) => {
            console.log('response =>', response);
            toast.success('Password reset successfully');
            navigate(URLS.LOGIN);
        }, (error: any) => {
            toast.error(error);
            console.log('error =>', error);
        });
    };

    return (
        <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img className="mx-auto h-10 w-auto" src="/logo.png" alt="Your Company"/>
                    <h2 className="mt-10 text-center text-2xl/9 font-bold tracking-tight text-gray-900">Reset Password</h2>
                    <p>Please check your email <b>{atob(params.email)}</b> for OTP</p>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form onSubmit={handleSubmit(resetPassword)}>
                    
                <div className='mb-10'>
                        <label className="block text-sm/6 font-medium text-gray-900">OTP</label>
                        <div className="mt-2">
                            <input type="password" {...register("otp", { required: true })} autoComplete='current-password' className={`block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 ${errors.password ? 'outline-red-500 outline-2' : 'outline-gray-300'} placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6`} />
                        </div>
                    </div>
                    <div className='mb-10'>
                        <label className="block text-sm/6 font-medium text-gray-900">Password</label>
                        <div className="mt-2">
                            <input type="password" {...register("password", { required: true })} autoComplete='current-password' className={`block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 ${errors.password ? 'outline-red-500 outline-2' : 'outline-gray-300'} placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6`} />
                        </div>
                    </div>

                    <div className='mb-10'>
                        <label className="block text-sm/6 font-medium text-gray-900">Confirm Password</label>
                        <div className="mt-2">
                            <input type="password" {...register("c_password", { required: true })} autoComplete='current-password' className={`block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 ${errors.c_password ? 'outline-red-500 outline-2' : 'outline-gray-300'} placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6`} />
                        </div>
                    </div>

                    <div className='mb-5'>
                        <button type="submit" className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Submit</button>
                    </div>
                </form>


            </div>
        </div>
    );
};

export default ResetPassword;