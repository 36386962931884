import { call, put } from 'redux-saga/effects';
import { ACTIONS, APIS, KEYS } from '../../_config';
import { CallApi } from '../api/callApi';
//import { ACTIONS, API_URL, STORAGE } from '../../_config'

//import { CallApi, responseHandler } from '../api/callApi';
//import { UpdateWithVariables } from '../../_common/functions';
// const API_BASE_URL = process.env.REACT_APP_API_URL;


export function* login(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, APIS.AUTH.LOGIN, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data ) {
            yield put({
                type: ACTIONS.USER.LOGIN,
                payload: {
                    user: resp.data.data.user,
                    accessToken: resp.data.data.token,
                    refreshToken: resp.data.token,
                    isAuthenticated: true,
                    permissions: resp.data.permissions
                }
            })
            action && action.callbackSuccess && action.callbackSuccess(resp.data.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data.data.statusMessage);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* register(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, APIS.AUTH.REGISTRATION, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data ) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data.data.statusMessage);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* forgotPassword(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, APIS.AUTH.FORGOT_PASSWORD, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data ) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data.data.statusMessage);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* resetPassword(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, APIS.AUTH.RESET_PASSWORD, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data ) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data.data.statusMessage);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* getDesigns(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, APIS.USER.GET_DESIGNS, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data ) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data.data.statusMessage);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* saveDesign(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, APIS.USER.SAVE_DESIGN, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data ) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data.data.statusMessage);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* updateDesign(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, APIS.USER.UPDATE_DESIGN, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data ) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data.data.statusMessage);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}


export function* getImages(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, APIS.USER.IMAGE_LIST, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data ) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data.data.statusMessage);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* uploadImage(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, APIS.USER.IMAGE_UPLOAD, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data ) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data.data.statusMessage);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

/* export function* logout(action: any): any {
    try {
        localStorage.removeItem(STORAGE)
        // sessionStorage.removeItem(UPDATED_USER)
        yield put({
            type: ACTIONS.USER.LOGOUT,
        });
        // yield put({
        //     type: ACTIONS.RESET_REDUCER,
        // });
        action && action.callbackSuccess && action.callbackSuccess({});
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
} */


/* export function* login(action: any): any {
    action.callbackSuccess({});
}
export function* logout(action: any): any {
    action.callbackSuccess({});
} */

/* export function* restPassword(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.USER.RESET_PASSWORD, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
        const resp = yield call(CallApi.POST, API, data, true);
        let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
      
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* restprofilePassword(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(API_URL.USER.PASSWORD_RESET, data.urlParams)
		if (data.urlParams) {
			delete data.urlParams
		}
        const resp = yield call(CallApi.POST, API, data, true);
        let respChecker = responseHandler(resp)
		if (respChecker.status) {
			action && action.callbackSuccess && action.callbackSuccess(respChecker.data)
		} else {
			action && action.callbackError && action.callbackError(respChecker.data)
		}
      
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
 */

