import React from 'react';
import { URLS } from '../_config';
import { useUserApi } from '../store/api/hooks/userApiHook';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const Registration: React.FC = () => {
    const uaerAPI:any = useUserApi();
    const { control, register, formState: { errors }, handleSubmit, } = useForm<any>();
    const navigate = useNavigate();

    const registerUser = (data: any) => {
        uaerAPI.register(data, (response: any) => {
            toast.success(response);
            navigate(URLS.LOGIN);
        }, (error: any) => {
            toast.error(error);
        });
    };

    return (
        <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img className="mx-auto h-10 w-auto" src="/logo.png" alt="Your Company"/>
                    <h2 className="mt-10 text-center text-2xl/9 font-bold tracking-tight text-gray-900">Registration</h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form onSubmit={handleSubmit(registerUser)}>
                    <div className='mb-5'>
                        <label className="block text-sm/6 font-medium text-gray-900">First Name</label>
                        <div className="mt-2">
                            <input type="text" {...register("first_name", { required: true })} autoComplete='current-password' className={`block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 ${errors.first_name?'outline-red-500 outline-2':'outline-gray-300'} placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6`}/>
                        </div>
                    </div>
                    <div className='mb-5'>
                        <label className="block text-sm/6 font-medium text-gray-900">Last Name</label>
                        <div className="mt-2">
                            <input type="text" {...register("last_name", { required: true })} autoComplete='current-password' className={`block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 ${errors.last_name?'outline-red-500 outline-2':'outline-gray-300'} placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6`}/>
                        </div>
                    </div>
                    <div className='mb-5'>
                        <label htmlFor="email" className="block text-sm/6 font-medium text-gray-900">Email address</label>
                        <div className="mt-2">
                            <input type="email" {...register("email", { required: true })} autoComplete='username' className={`block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 ${errors.email?'outline-red-500 outline-2':'outline-gray-300'} placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6`}/>
                        </div>
                    </div>
                    <div className='mb-5'>
                        <label className="block text-sm/6 font-medium text-gray-900">Phone</label>
                        <div className="mt-2">
                            <input type="text" {...register("phone", { required: true })} autoComplete='username' className={`block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 ${errors.phone?'outline-red-500 outline-2':'outline-gray-300'} placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6`}/>
                        </div>
                    </div>

                    <div className='mb-5'>
                        <label className="block text-sm/6 font-medium text-gray-900">Password</label>
                        <div className="mt-2">
                            <input type="password" {...register("password", { required: true })} autoComplete='current-password' className={`block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 ${errors.password?'outline-red-500 outline-2':'outline-gray-300'} placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6`} />
                        </div>
                    </div>
                    <div className='mb-10'>
                        <label className="block text-sm/6 font-medium text-gray-900">Confirm Password</label>
                        <div className="mt-2">
                            <input type="password" {...register("confirm_password", { required: true })} autoComplete='current-password' className={`block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 ${errors.confirm_password?'outline-red-500 outline-2':'outline-gray-300'} placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6`} />
                        </div>
                    </div>

                    <div className='mb-5'>
                        <button type="submit" className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Submit</button>
                    </div>
                </form>

                <p className="mt-10 text-center text-sm/6 text-gray-500">
                    Already a member?&nbsp;
                    <Link to={URLS.LOGIN} className="font-semibold text-indigo-600 hover:text-indigo-500">Sign In</Link>
                </p>
            </div>
        </div>
    );
};

export default Registration;