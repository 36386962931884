import { ACTIONS, KEYS } from '../../_config'
import { ActionExtended } from '../../_config/interfaces/interfaces';

export interface OrganizationReducer {
	organization: any;
}

const initialState: OrganizationReducer = {
	organization: null,
};

const organizationReducer = (state = initialState, action: ActionExtended) => {
	switch (action.type) {
		case ACTIONS.ORGANIZATION.ORGANIZATION:
			return {
				...state,
				organization: action.payload,
			};
		default:
			return state;
	}
};

export default organizationReducer;
