import React, { useEffect } from 'react';
import LoaderSVG from '../../components/iconSvgs/loader';
// import "./DesignList.css";
import { useUserApi } from '../../store/api/hooks/userApiHook';
import { useUserSelector } from '../../store/selectors/userSelector';
import { useOrganization } from '../../store/selectors/organizationSelector';
import { useEditorApi } from '../../store/api/hooks/editorApiHook';
import { useDispatch } from 'react-redux';
import { ACTIONS, URLS } from '../../_config';
import { Link, useNavigate } from 'react-router-dom';
import { showDate } from '../../_common/functions';
import Icon from '../../components/icons';

const DesignList: React.FC = () => {
    const editorAPI: any = useEditorApi();
    const userAPI: any = useUserApi();
    const user: any = useUserSelector();
    const organizationData: any = useOrganization();
    const [designList, setDesignList] = React.useState<any>(null);

    const [jerseys, setJerseys] = React.useState<any[] | null>(null);
    const [pants, setPants] = React.useState<any[] | null>(null);

    const dispatch = useDispatch();
    const navigate: any = useNavigate();

    // console.log('editorData =>', organizationData);

    const getItems = () => {
        editorAPI.getItems({ app_id: organizationData?.app_id }, (message: any, response: any) => {
            let items: any = response.list;
            items = items.map((item: any) => {
                item.data = JSON.parse(item.data);
                return item;
            });
            setJerseys(items);

            getDesignList(items);
            /* let jerseys:any = items.filter((item:any) => item.category == 'Tshirt');
            let pants:any = items.filter((item:any) => item.category == 'Pant');
            setJerseys(jerseys);
            setPants(pants); */
        }, (error: any) => {
            console.error('error =>', error);
        });
    }

    const getDesignList = (items: any) => {
        userAPI.getDesigns({ app_id: organizationData.app_id, user_id: user.userID },
            (message: any, data: any) => {
                setDesignList(data?.list.map((item: any) => {
                    item.item = items?.filter((jersey: any) => jersey.id == item.item_id)[0];
                    return item;
                }));
            }, (error: any) => {
                console.log('error =>', error);
                setDesignList([]);
            });
    }

    const selectItemForDesign = (item: any) => {
        dispatch({ type: ACTIONS.EDITOR.UPDATE_DESIGN, payload: { design: item } });
        navigate(URLS.DESIGN);
    }

    const Download = (url:string,name:string) => {
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.download = name; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        if (organizationData?.app_id) {
            getItems();
        }
    }, [organizationData]);

    useEffect(() => {
        console.log('designList =>', designList);
    }, [designList]);

    const imageShowHandler = (e: any) => {
        const front = e.currentTarget.querySelector('.front');
        const back = e.currentTarget.querySelector('.back');

        back.style.transition = 'transform 0.5s ease-in-out';
        front.style.transition = 'transform 0.5s ease-in-out';

        setTimeout(() => {
            front.style.transform = 'translateX(-100%)';
            back.style.transform = 'translateX(-100%)';
        }, 0);

        e.currentTarget.onmouseleave = () => {
            front.style.transform = 'translateX(0)';
            back.style.transform = 'translateX(0)';
        };
    }

    return (
        <div style={{ textAlign: 'center' }}>
            <h1 className='text-2xl'>My Design List</h1>
            <hr />
            {(designList === null) && <div><LoaderSVG size={50} /></div>}
            {(designList && designList?.length == 0) && <div>No data found.</div>}
            <div className='grid grid-cols-6 gap-4'>
                {
                    designList && designList?.map((item: any, index: number) => {
                        return (
                            <div key={`designList${item.id}`} className='m-2 border border-gray-300 rounded-lg overflow-hidden cursor-pointer bg-slate-100'>
                                <div className='flex' onMouseMove={imageShowHandler} onClick={() => {
                                    selectItemForDesign({ ...JSON.parse(item.design_data), "design_id": item.id, "design_name": item.design_name });

                                }}>
                                    <img src={item?.front_thumbnail} alt={item?.design_name} className='p-2 front' />
                                    <img src={item?.back_thumbnail} alt={item?.design_name} className='p-2 back' />
                                </div>
                                <h3 className='font-semibold bg-slate-600 text-slate-50 p-2'>
                                    {(item?.design_name) ? item?.design_name : item?.item?.item_name}
                                </h3>
                                <div className='flex gap-2 p-2'>
                                {
                                    item?.roster && <Link to={(item?.roster) ? item?.roster : '#'}><Icon name='xls' size={20} title='Download Rosters'/></Link>
                                }
                                {item.pdf && <Icon name='pdf' onClick={()=>{Download(item.pdf,'download.pdf')}} size={20} title='Download PDF'/>}
                                </div>
                                {/* <h3>{item.category}</h3>  */}
                                {/*  <h3>{item.isOrdered}</h3> */}
                                
                                <p className='text-xs'>Updated on: {showDate(item?.updated_at)}</p>
                            </div>
                        )
                    }
                    )
                }
            </div>
        </div>
    );
};

export default DesignList;