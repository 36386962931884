import { SAGA_ACTIONS } from "../../../_config";
import { useApiCall } from "../appApiCallHook";



export function useEditorApi() {
  const callApi = useApiCall();

  const getOrganization = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.EDITOR.GET_ORGANIZATION, data, onSuccess, onError);
  };

  const getItems = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.EDITOR.GET_ITEMS, data, onSuccess, onError);
  };


  return {
    getItems,
    getOrganization
  };
}
