import { takeLatest, all, takeEvery } from 'redux-saga/effects'; // fork, take,  takeEvery, takeLeading
import { SAGA_ACTIONS } from '../../_config';

import * as userSaga from './userSaga';
import * as editorSaga from './editorSaga';


export function* sagas() {
	yield all([
		takeEvery(SAGA_ACTIONS.USER.LOGIN, userSaga.login),
		takeEvery(SAGA_ACTIONS.USER.REGISTER, userSaga.register),
		takeEvery(SAGA_ACTIONS.USER.FORGET_PASSWORD, userSaga.forgotPassword),
		takeEvery(SAGA_ACTIONS.USER.RESET_PASSWORD, userSaga.resetPassword),

		takeEvery(SAGA_ACTIONS.USER.GET_DESIGNS, userSaga.getDesigns),
		takeEvery(SAGA_ACTIONS.USER.SAVE_DESIGN, userSaga.saveDesign),
		takeEvery(SAGA_ACTIONS.USER.UPDATE_DESIGN, userSaga.updateDesign),
		takeEvery(SAGA_ACTIONS.USER.IMAGE_LIST, userSaga.getImages),
		takeEvery(SAGA_ACTIONS.USER.IMAGE_UPLOAD, userSaga.uploadImage),

		takeEvery(SAGA_ACTIONS.EDITOR.GET_ORGANIZATION, editorSaga.getOrganization),
		takeLatest(SAGA_ACTIONS.EDITOR.GET_ITEMS, editorSaga.getItems),
		/* takeEvery(SAGA_ACTIONS.USER.LOGOUT, userSaga.logout), */




	]);
}