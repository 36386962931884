import { useSelector } from 'react-redux'
import { StateExtended } from '../../_config/interfaces/stateExtended'


export function useAuthStatus() {
  const isAuthenticated = useSelector((state: StateExtended) => state.user.isAuthenticated)
  return isAuthenticated
}
export function useUserSelector() {
  const user = useSelector((state: StateExtended) => state.user.user)
  return user
}
