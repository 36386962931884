import { call, put } from 'redux-saga/effects';
import { ACTIONS, APIS, KEYS } from '../../_config';
import { CallApi } from '../api/callApi';
import { UpdateWithVariables } from '../../_common/functions';

export function* getOrganization(action: any): any {
    try {
        const data = action.payload;
        let API = UpdateWithVariables(APIS.EDITOR.GET_ORGANIZATION, data.urlParams)
        if (data.urlParams) {
            delete data.urlParams
        }
        const resp = yield call(CallApi.GET, API, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data ) {
           yield put({
                type: ACTIONS.EDITOR.ORGANIZATION,
                payload: {
                    // organization: resp.data.data
                    organization: resp.data.data?.details?.[0]
                }
            })
            action && action.callbackSuccess && action.callbackSuccess(resp.data.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data.data.statusMessage);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getItems(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, APIS.EDITOR.GET_ITEMS, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data ) {
           /*  yield put({
                type: ACTIONS.USER.LOGIN,
                payload: {
                    user: resp.data.data.user,
                    accessToken: resp.data.data.token,
                    refreshToken: resp.data.token,
                    isAuthenticated: true,
                    permissions: resp.data.permissions
                }
            }) */
            action && action.callbackSuccess && action.callbackSuccess(resp.data.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data.data.statusMessage);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
