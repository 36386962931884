import React from 'react';
import { Link } from 'react-router-dom';
import { URLS } from '../_config';
import Header from '../components/header';
import { useUserSelector } from '../store/selectors/userSelector';

const Profile: React.FC = () => {

    const user:any = useUserSelector();
    return (
        <div className='h-screen flex flex-col'>
            <Header />
            <div className='flex flex-grow'>
                <div className='w-full h-full flex justify-center items-center'>
                    <div className=' rounded-md bg-red-100 p-5 min-w-[400px]'>
                        <div className='flex flex-col items-center justify-center mb-2'>
                            <div className='rounded-full w-[120px] h-[120px] overflow-hidden mb-2 border-4 shadow-md shadow-gray-400 border-gray-50'>
                                <img src={user?.avatar?.original} alt="" className='w-full' />
                            </div>
                            <p className='font-bold'>{user.first_name} {user.last_name}</p>
                            <p className='text-sm'>{user.email}</p>
                            <p className='text-md font-semibold text-red-800'>{user.roll}</p>

                        </div>
                        <div className="my-1 h-px bg-red-300" />
                        <div className=' rounded-md bg-red-200 p-5 w-full'>
                            <p className='text-sm mb-1'><span className='font-bold'>Phone:</span> {user.phone}</p>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    );
};

export default Profile;