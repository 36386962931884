import React from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import Icon from './icons';
import { useDispatch } from 'react-redux';
import { ACTIONS, URLS } from '../_config';
import { Link } from 'react-router-dom';
import { useUserSelector } from '../store/selectors/userSelector';
import noUserImg from '../images/no-profile.jpg';
import noImg from '../images/no-image.jpg';
import { useOrganization } from '../store/selectors/organizationSelector';
const Header: React.FC = () => {

    const dispach = useDispatch();
    const user:any = useUserSelector();
    const organization: any = useOrganization();

    return (
        <header className='bg-gray-900 h-[60px] relative flex-shrink-0 '>
            <div className='flex items-center justify-between h-full px-3'>
                <div></div>
                <div>
                    <Menu as="div" className="relative text-left">
                       
                            <MenuButton className="flex w-full justify-center gap-x-1.5 rounded-md  text-sm font-semibold text-gray-900 shadow-sm">
                                <div className='rounded-full w-[32px] h-[32px] overflow-hidden'>
                                    {/* <img src={user?.avatar?.thumb} alt="" className='w-full' /> */}
                                    <img src={(user?.profilePicture !== '') ? user?.profilePicture : noUserImg} alt="" className='w-full' />
                                </div>
                            </MenuButton>
                       

                        <MenuItems
                            transition
                            className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg p-2 ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                        >
                            <div className='flex flex-col items-center justify-center mb-2'>
                                <div className='rounded-full w-[64px] h-[64px] overflow-hidden'>
                                    <img src={(user?.profilePicture !== '') ? user?.profilePicture : noUserImg} alt="" className='w-full' />
                                </div>
                                <p className='text-sm font-medium'>{user.first_name} {user.last_name}</p>
                                <p className='text-xs'>{user.email}</p>
                            </div>
                            <div className="my-1 h-px bg-black/5" />
                            <MenuItem>
                            <Link to={URLS.PROFILE} className="group flex w-full text-sm items-center gap-2 rounded-sm py-1.5 px-3 data-[focus]:bg-black/5" >
                                    <Icon name='user' size={20} className='text-gray-600' />
                                    Profile
                                    {/* <kbd className="ml-auto hidden font-sans text-xs text-white/50 group-data-[focus]:inline">⌘E</kbd> */}
                                </Link>
                            </MenuItem>
                            
                            <MenuItem>
                                <button className="group flex w-full text-sm items-center gap-2 rounded-sm py-1.5 px-3 data-[focus]:bg-black/5" onClick={()=>{
                                    //(window as any).close();
                                    dispach({type:ACTIONS.USER.LOGOUT, payload:{}})
                                }}>
                                    <Icon name='logout' size={20} className='text-red-700' /> Logout
                                    {/* <kbd className="ml-auto hidden font-sans text-xs text-white/50 group-data-[focus]:inline">⌘E</kbd> */}
                                </button>
                            </MenuItem>
                        </MenuItems>
                    </Menu>
                </div>
            </div>
            <div className='absolute top-1/2 left-1/2 z-10 -translate-x-1/2 -translate-y-1/2'>
                <Link to={URLS.HOME}><img src={(organization?.logo) ? organization?.logo : noImg} alt={organization?.app_name} className='h-[64px] py-2' /></Link>
            </div>

        </header>
    );
};

export default Header;