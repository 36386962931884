import React, { useReducer, useRef } from 'react';
import Header from '../../components/header';
import Editor from './editor';
import DesignView from './designView';
import { API_URL } from '../../_config';


const Design: React.FC = () => {
    const svgRef:any = useRef();

    return (
        <div className='h-screen flex flex-col'>
            <Header />
            <div className='flex flex-grow'>
                <Editor svgRef={svgRef} />
                <DesignView svgRef={svgRef}/>
            </div>
        </div>
    );
};

export default Design;