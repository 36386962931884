import { SAGA_ACTIONS } from "../../../_config";
import { useApiCall } from "../appApiCallHook";



export function useUserApi() {
  const callApi = useApiCall();

  const login = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.LOGIN, data, onSuccess, onError);
  };
  const register = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.REGISTER, data, onSuccess, onError);
  };
  const forgotPassword = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.FORGET_PASSWORD, data, onSuccess, onError);
  };
  const resetPassword = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.RESET_PASSWORD, data, onSuccess, onError);
  };
  const getDesigns = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.GET_DESIGNS, data, onSuccess, onError);
  };
  const saveDesign = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.SAVE_DESIGN, data, onSuccess, onError);
  };
  const updateDesign = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.UPDATE_DESIGN, data, onSuccess, onError);
  };

  const getImages = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.IMAGE_LIST, data, onSuccess, onError);
  };
  const uploadImage = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.IMAGE_UPLOAD, data, onSuccess, onError);
  };


  return {
    login,
    register,
    forgotPassword,
    resetPassword,
    getDesigns,
    saveDesign,
    updateDesign,

    getImages,
    uploadImage,
  };
}
